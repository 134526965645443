const XIcon = (props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6899 35.9391C27.4599 35.9391 35.3799 28.019 35.3799 18.2491C35.3799 8.47917 27.4599 0.559082 17.6899 0.559082C7.92002 0.559082 -6.10352e-05 8.47917 -6.10352e-05 18.2491C-6.10352e-05 28.019 7.92002 35.9391 17.6899 35.9391Z"
      fill="white"
    />
    <g clipPath="url(#clip0_5_230)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5851 16.9371L26.1438 9.48047H24.0569L22.7417 10.9685L18.6552 15.6165L14.7447 10.0648L14.3333 9.48049H8.718L15.5362 19.1611L8.63373 27.0122H10.7209L16.4657 20.4809L20.6575 26.4328L21.0692 27.0166H26.6844L25.2443 24.9715L19.5851 16.9371ZM19.4208 17.1238L19.422 17.1225L25.0004 25.0425L26.2266 26.7838H26.2246L24.9989 25.0431V25.043L19.4208 17.1238ZM18.4921 18.1795L23.6054 25.4394H21.9263L17.5586 19.238L17.5607 19.2356L16.6308 17.9156L16.6287 17.918L11.7968 11.0574H13.4758L17.5623 16.8596L17.562 16.8599L18.4918 18.1797L18.4921 18.1795ZM18.491 15.8034L14.5408 10.1953L14.2018 9.71387H9.17618L9.17575 9.71327H14.2034L18.4921 15.8022L18.491 15.8034ZM25.6201 9.71387H24.1742L22.9325 11.1187L18.7989 15.8205L18.7968 15.8175L22.9285 11.1181L24.1702 9.71325H25.6207L25.6201 9.71387ZM18.6336 16.0032L18.6326 16.0044L18.6346 16.0073L18.6357 16.0061L18.6336 16.0032ZM16.324 20.2797L10.6072 26.7794H10.6038L16.3226 20.2777L16.324 20.2797ZM17.3944 19.4248L21.7949 25.6729V25.6728H24.0617L24.0612 25.6722H21.7964L17.3954 19.4236L17.3944 19.4248ZM17.2538 19.2226L17.2528 19.2238L17.2512 19.2216L17.2523 19.2204L17.2538 19.2226ZM16.4656 18.1035L11.3394 10.8252H11.3375L16.4645 18.1049L16.4656 18.1035Z"
        fill="#0A1C32"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_230">
        <rect width="18" height="18" fill="white" transform="translate(8.68994 9.24908)" />
      </clipPath>
    </defs>
  </svg>
)

export default XIcon
