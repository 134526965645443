/* eslint-disable lodash/import-scope */
// import { useAllBlockchain } from 'state/home/fetchAllBlockChain'

// import {useWeb3React} from '../../../packages/wagmi/src/useWeb3React'
// import TransactionBridge from './components/TransactionBridge'
// eslint-disable-next-line lodash/import-scope
import FacebookIcon from 'components/Menu/FacebookIcon'
import TelegramIcon from 'components/Menu/TelegramIcon'
import Image from 'next/image'
import styled from 'styled-components'

import XIcon from 'components/Menu/XIcon'
import YoutubeIcon from 'components/Menu/YoutubeIcon'
import ArrowIcon from 'components/Svg/ArrowIcon'
import * as Styles from './styles'

const FooterContainer = styled.div`
  position: absolute;
  bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const BottomLinkGrid = styled.div`
  gap: 22px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
`

const Styled04 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding-top: 200px;
  margin-left: 300px;
  @media (min-width: 768px) {
    padding-top: 300px;
  }
  @media (max-width: 500px) {
    width: 170px;
    padding-top: 275px;
    margin-left: 250px;
  }
`

const Styled03 = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 375px;
  min-height: 586px;
`

const Home = ({ pageSupportedChains }: { pageSupportedChains: number[] }) => {
  return (
    <Styles.StyledHome>
      <div className="content">
        <div className="left">
          <div
            style={{
              fontSize: '72px',
              fontWeight: 800,
            }}
          >
            <span className="text-gradient">Ting</span> Whales
          </div>
          <div style={{ color: '#FFFFFF99' }}>Trade like a pro with advanced tools and features at your fingertips</div>
          <div className="trade-btn">
            <a href="https://beurl.app/FBIzZpGD" target="_blank" rel="noreferrer" style={{ color: '#0e1420' }}>
              Trade Now
            </a>
            <ArrowIcon />
          </div>
        </div>
        <div className="right">
          <Image src="/images/introduction_media.png" alt="whale" width={836} height={610} />
        </div>
      </div>
      <FooterContainer>
        <BottomLinkGrid>
          <a href="https://beurl.app/iiyQUiOe" target="_blank" rel="noreferrer">
            <TelegramIcon />
          </a>
          <a href="https://beurl.app/pylfXWaY" target="_blank" rel="noreferrer">
            <XIcon />
          </a>
          <a href="https://beurl.app/ynowSChk" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://beurl.app/iXhCGayd" target="_blank" rel="noreferrer">
            <YoutubeIcon />
          </a>
        </BottomLinkGrid>
        <div style={{ width: 117.34, background: '#D9D9D9', height: 0.5, marginTop: 12, marginBottom: 12 }} />
        <div>contact@tingwhales.com</div>
      </FooterContainer>
    </Styles.StyledHome>
  )
}

export default Home
