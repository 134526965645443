const YoutubeIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0099 35.0593C27.5369 35.0593 35.2601 27.3362 35.2601 17.8092C35.2601 8.28222 27.5369 0.559082 18.0099 0.559082C8.48296 0.559082 0.759827 8.28222 0.759827 17.8092C0.759827 27.3362 8.48296 35.0593 18.0099 35.0593Z"
      fill="white"
    />
    <path
      d="M27.0048 13.5668C26.9035 13.1617 26.697 12.7907 26.4063 12.491C26.1156 12.1913 25.751 11.9736 25.3492 11.86C23.8814 11.5015 18.01 11.5015 18.01 11.5015C18.01 11.5015 12.1385 11.5015 10.6707 11.8941C10.2689 12.0078 9.90429 12.2254 9.6136 12.5251C9.3229 12.8248 9.11644 13.1959 9.01506 13.6009C8.74642 15.0906 8.61502 16.6017 8.6225 18.1154C8.61292 19.6405 8.74434 21.1632 9.01506 22.6641C9.12682 23.0565 9.33791 23.4135 9.62793 23.7005C9.91795 23.9875 10.2771 24.1949 10.6707 24.3026C12.1385 24.6952 18.01 24.6952 18.01 24.6952C18.01 24.6952 23.8814 24.6952 25.3492 24.3026C25.751 24.1889 26.1156 23.9713 26.4063 23.6716C26.697 23.3719 26.9035 23.0008 27.0048 22.5958C27.2714 21.1173 27.4028 19.6177 27.3974 18.1154C27.407 16.5903 27.2756 15.0676 27.0048 13.5668Z"
      fill="#0A1C32"
      stroke="#0A1C32"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0898 20.906L20.9969 18.1154L16.0898 15.3248V20.906Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default YoutubeIcon
