const TelegramIcon = () => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7498 35.0593C27.2768 35.0593 34.9999 27.3362 34.9999 17.8092C34.9999 8.28222 27.2768 0.559082 17.7498 0.559082C8.22283 0.559082 0.499695 8.28222 0.499695 17.8092C0.499695 27.3362 8.22283 35.0593 17.7498 35.0593Z"
      fill="white"
    />
    <path
      d="M25.7989 11.2253C25.7254 11.6037 25.6542 11.9828 25.5779 12.3606C25.2211 14.1286 24.8633 15.8965 24.5046 17.6641C24.0836 19.7432 23.6627 21.822 23.2419 23.9006C23.1748 24.2323 23.1086 24.5643 23.0412 24.8963C23.0339 24.9322 23.024 24.9681 23.0145 25.0038C22.77 25.9234 22.0831 26.0268 21.4086 25.5463C20.3131 24.7659 19.2415 23.9525 18.1597 23.153C17.8913 22.9546 17.619 22.7603 17.3551 22.5571C17.2642 22.4871 17.2092 22.4976 17.1284 22.5765C16.5028 23.1868 15.8738 23.7938 15.2414 24.3973C15.1009 24.5339 14.9479 24.6571 14.7844 24.7653C14.4346 24.992 14.0426 24.9007 13.8568 24.5287C13.716 24.2466 13.6155 23.9426 13.5166 23.6418C13.0652 22.27 12.6184 20.8966 12.1759 19.5218C12.1365 19.3994 12.0757 19.3336 11.9485 19.2945C10.6747 18.9024 9.40458 18.502 8.13038 18.1071C7.90428 18.0371 7.70871 17.9328 7.6025 17.7093C7.45241 17.3938 7.58565 17.0195 7.94149 16.8227C8.13419 16.7162 8.34852 16.6471 8.55585 16.5683C11.9838 15.2694 15.4123 13.9711 18.8411 12.6735C20.7113 11.9654 22.5821 11.2586 24.4534 10.5531C24.6442 10.4812 24.8458 10.41 25.0462 10.3969C25.4736 10.369 25.7553 10.6491 25.7757 11.0778C25.7782 11.1255 25.7757 11.1732 25.7757 11.2209L25.7989 11.2253ZM14.3564 23.7315L14.3796 23.7283C14.4941 22.724 14.6121 21.7201 14.7205 20.7156C14.7418 20.5184 14.7987 20.3677 14.9568 20.2262C17.4357 18.0068 19.9103 15.7828 22.3807 13.5543C22.4198 13.519 22.4777 13.4863 22.4898 13.4424C22.5146 13.3524 22.5143 13.2554 22.5245 13.1616C22.4405 13.1546 22.3553 13.1368 22.2729 13.1451C22.2192 13.1505 22.167 13.1905 22.1177 13.2211C21.879 13.3695 21.641 13.5179 21.4035 13.6663L13.7878 18.4451C13.4698 18.6445 13.1519 18.8448 12.8291 19.0493L14.3564 23.7315Z"
      fill="#0A1C32"
    />
  </svg>
)
export default TelegramIcon
