const FacebookIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0699 35.9391C27.8398 35.9391 35.7599 28.019 35.7599 18.2491C35.7599 8.47917 27.8398 0.559082 18.0699 0.559082C8.29999 0.559082 0.379913 8.47917 0.379913 18.2491C0.379913 28.019 8.29999 35.9391 18.0699 35.9391Z"
      fill="white"
    />
    <path
      d="M23.225 8.87616H20.4132C19.1702 8.87616 17.9782 9.36991 17.0993 10.2488C16.2205 11.1277 15.7267 12.3197 15.7267 13.5626V16.3745H12.9148V20.1237H15.7267V27.622H19.4759V20.1237H22.2878L23.225 16.3745H19.4759V13.5626C19.4759 13.314 19.5746 13.0756 19.7504 12.8999C19.9262 12.7241 20.1646 12.6253 20.4132 12.6253H23.225V8.87616Z"
      fill="#0A1C32"
      stroke="#0A1C32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FacebookIcon
